<template>
    <div class="grid">
        <div class="col-12">
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div :style="loading ? 'opacity: 30%;' : ''">
                <div class="card p-fluid w-full">
                    <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                        <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                            <AppInfoManual nomeTelaDoManual="ambiente-form" />
                            <h3 class="ml-1">{{ $route.meta.title }}</h3>
                        </div>
                    </div>
                    <Toast />

                    <div class="field">
                        <label for="nome">Nome</label>
                        <InputText
                            id="nome"
                            v-model.trim="form.nome"
                            required="true"
                            autofocus
                            autocomplete="off"
                            :class="{ 'p-invalid': submitted && !form.nome }"
                        />
                        <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                    </div>

                    <div class="field">
                        <label for="procedimentos">Exames</label>
                        <MultiSelect
                            :filter="true"
                            v-model="form.procedimentos"
                            :options="procedimentos"
                            optionLabel="name"
                            placeholder="Selecione os exames"
                            dataKey="id"
                        />
                    </div>

                    <div class="field">
                        <label for="tempoAtendimento">Tempo de atendimento</label>
                        <AppInputTempo id="tempoConsultaManha" v-model="form.tempoAtendimento" :required="true" :submitted="submitted" />
                    </div>

                    <div class="grid">
                        <div class="field col-6">
                            <label for="tempoConsultaManha">Tempo consulta manhã</label>
                            <AppInputTempo id="tempoConsultaManha" v-model="form.tempoConsultaManha" :required="true" :submitted="submitted" />
                        </div>
                        <div class="field col-6">
                            <label for="tempoConsultaTarde">Tempo consulta tarde</label>
                            <AppInputTempo id="tempoConsultaTarde" v-model="form.tempoConsultaTarde" :required="true" :submitted="submitted" />
                        </div>
                    </div>

                    <div class="grid">
                        <div class="field col-6">
                            <label for="codigoAgendaSoc">Código agenda SOC</label>
                            <InputText id="codigoAgendaSoc" v-model="form.codigoAgendaSoc" />
                        </div>
                    </div>

                    <div class="field">
                        <label for="descricao">Descrição</label>
                        <Textarea id="descricao" v-model.trim="form.descricao" :autoResize="true" />
                    </div>

                    <div class="flex mt-5">
                        <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                        <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.go(-1)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppInfoManual from '../../components/AppInfoManual.vue';
import AreaMedicaService from '../../services/AreaMedicaService';
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            form: {},
            procedimentos: [],
            submitted: false,
            loading: false
        };
    },
    async mounted() {
        this.loading = true;
        this.$service = new AreaMedicaService('/ambientes');
        this.$serviceProcedimentos = new BaseService('/procedures');
        try {
            this.$serviceProcedimentos.findAll({ page: 1, limit: 999 }).then(({ data }) => (this.procedimentos = data.items));
            if (this.$route.params.idAmbiente) {
                const { data } = await this.$service.findById(this.$route.params.idAmbiente);
                this.form = data;
            }
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    methods: {
        async save() {
            this.submitted = true;
            try {
                await this.$service.save(this.form);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Ambiente salvo com sucesso!',
                    life: 3000
                });
                this.$router.go(-1);
            }
            catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Problemas ao salvar o ambiente! ' + (err.response?.data?.message ?? err.message),
                    life: 3000
                });
            }
        }
    },
    components: { AppInfoManual }
};
</script>

